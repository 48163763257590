import React from "react";
import Texts from "../../../utils/lang-utils";
import background from '../../../areas/main/assets/images/background/banner-2-bg.jpg';
import "./Features.scss";
import urls from "../../../areas/main/assets/urls";

const OtherServices = () => {
    return (
        <section className="service-one d-flex align-items-center features" style={{ backgroundImage: `url('${background}')` }}>
            <div className="container text-center ">
                <div className="block-title text-center">
                    <h2 className="block-title__title light" dangerouslySetInnerHTML={{ __html: Texts.QROrdering.FeaturesTitle }} />
                </div>
                <div className="row">

                    <div className=" col-md-6 text-left">
                        <h3>{Texts.QROrdering.FeaturesSubtitle1}</h3>
                        <p><i className="fa fa-check"></i> {Texts.QROrdering.FeaturesGuestsItem1}</p>
                        <p><i className="fa fa-check"></i> {Texts.QROrdering.FeaturesGuestsItem2}</p>
                        <p><i className="fa fa-check"></i> {Texts.QROrdering.FeaturesGuestsItem3}</p>
                        <p><i className="fa fa-check"></i> {Texts.QROrdering.FeaturesGuestsItem4}</p>
                        <p><i className="fa fa-check"></i> {Texts.QROrdering.FeaturesGuestsItem5}</p>

                    </div>

                    <div className=" col-md-6 text-left">
                        <h3>{Texts.QROrdering.FeaturesSubtitle2}</h3>
                        <p><i className="fa fa-check"></i> {Texts.QROrdering.FeaturesOwnersItem1}</p>
                        <p><i className="fa fa-check"></i> {Texts.QROrdering.FeaturesOwnersItem2}</p>
                        <p><i className="fa fa-check"></i> {Texts.QROrdering.FeaturesOwnersItem3}</p>
                        <p><i className="fa fa-check"></i> {Texts.QROrdering.FeaturesOwnersItem4}</p>
                        <p><i className="fa fa-check"></i> {Texts.QROrdering.FeaturesOwnersItem5}</p>
                    </div>

                </div>
                <div>
                    <div className="text-center mt-5">
                        <a href={urls.onboarding} className="thm-btn">
                            <span>{Texts.GetStarted}</span>
                        </a>
                    </div>

                </div>
            </div>
        </section>
    );
};
export default OtherServices;
